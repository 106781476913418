import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Script } from "gatsby";
import ResearchAndTutorialCarousel from "../partials/ResearchAndTutorialCarousel";

const SupportPlans = ({ location }) => {
  return (
    <Layout location={location}>
      <section>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-4 md:pt-40 md:pb-4">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-10 md:pb-10 z-0">
              <h1 className="h1 mb-4">Support Packages</h1>
              <p className="text-xl text-gray-600">
                Encord support is here to help.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*  Body of text  */}
      <section>
        <div className="max-w-5xl mx-auto px-4 sm:px-6">
          <div>
            <div>
              <div className="max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-8 md:grid-cols-2 xl:grid-cols-3 xl:gap-6 items-start">
                {/* Pricing table 2 */}
                <div className="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-1">
                      Starter Support
                    </div>
                    <div className="text-lg text-gray-800">Self-serve.</div>
                  </div>
                  <ul className="text-gray-600 -mb-2 flex-grow">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Self-serve on-boarding through documentation</span>
                    </li>
                  </ul>
                </div>

                {/* Pricing table 3 */}
                <div className="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-1">
                      Team Support
                    </div>
                    <div className="text-lg text-gray-800">
                      Hands-on assistance.
                    </div>
                  </div>
                  <ul className="text-gray-600 -mb-2 flex-grow">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>48-hour response time (SLA)</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Live on-boarding</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Email support</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>ML Solutions Architect add-on</span>
                    </li>
                  </ul>
                </div>

                {/* Pricing table 3 */}
                <div className="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl">
                  <div className="mb-4">
                    <div className="text-lg font-bold mb-1">
                      Premium Support
                    </div>
                    <div className="text-lg text-gray-800">
                      Enterprise-grade assistance.
                    </div>
                  </div>
                  <ul className="text-gray-600 -mb-2 flex-grow">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>24-hour response time (SLA)</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Live on-boarding & training sessions</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Private Slack channel for support</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Security & compliance on-boarding</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Dedicated Customer Success Manager</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="https://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Priority ML Solutions Architect add-on</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <ResearchAndTutorialCarousel />

          <div className="mt-10 md:mt-10 pb-10 md:pb-10">
            <a
              href="https://www.g2.com/products/encord/reviews?utm_source=review-widget/"
              title="Read reviews of Encord on G2"
            >
              <img
                className="mx-auto max-w-[230px] w-full h-auto"
                alt="Read Encord reviews on G2"
                src="https://images.prismic.io/encord/82ebca44-b893-4ab5-86dc-4db417f082c5_gray-10.png?auto=compress,format"
                loading="lazy"
                width={0}
                height={0}
              />
            </a>

            <Script id="redit-pixel-tracking-script" defer>
              {`(function(a,b,c,d){window.fetch("https://www.g2.com/products/encord/rating_schema.json").then(e=>e.json()).then(f=>{c=a.createElement(b);c.type="application/ld+json";c.text=JSON.stringify(f);d=a.getElementsByTagName(b)[0];d.parentNode.insertBefore(c,d);});})(document,"script");`}
            </Script>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SupportPlans;

export const Head = () => (
  <SEO
    title="Support Plans | Encord"
    description="Encord Support Plans to Help You as You Scale."
  />
);
